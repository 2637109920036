import * as React from "react"

import "../../scss/index.scss"
import "./layout.scss"

import Navbar from "../navbar/navbar"
import Footer from "../footer/footer"

type LayoutProps = {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="layout">
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
