import {
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

export const socialIdToIcon = (id: string) => {
  switch (id) {
    case "twitter":
      return faTwitter
    case "facebook":
      return faFacebook
    case "instagram":
      return faInstagram
    case "youtube":
      return faYoutube
    case "github":
      return faGithub
    case "linkedin":
      return faLinkedin

    default:
      throw Error(`the argument (social id) ${id} is not supported`)
  }
}
