import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import IconButton from "../buttons/iconButton"
import { socialIdToIcon } from "../../core/utils/socialIdToIcon"

type TypeSocialQuery = {
  allSanitySocial: {
    edges: {
      node: {
        uniqueId: string
        title: string
        socialUrl: string
      }
    }[]
  }
}

const SocialButtonsBar = () => {
  const { allSanitySocial }: TypeSocialQuery = useStaticQuery(
    graphql`
      query {
        allSanitySocial {
          edges {
            node {
              uniqueId
              title
              socialUrl
            }
          }
        }
      }
    `
  )
  const socials = allSanitySocial.edges.map(edge => edge.node)

  return (
    <div className="row">
      {socials.map(social => (
        <IconButton
          key={social.uniqueId}
          label={social.title}
          href={social.socialUrl}
          icon={socialIdToIcon(social.uniqueId)}
          isExternalLink={true}
        />
      ))}
    </div>
  )
}

export default SocialButtonsBar
