import * as React from "react"
import { AnimatePresence, motion } from "framer-motion"

type AccordionProps = {
  children: React.ReactNode
  i: number
  expanded: number | false
}

const Accordion = ({ children, i, expanded }: AccordionProps) => {
  const isOpen = i === expanded

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <React.Fragment>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </motion.section>
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default Accordion
