import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

import "./textButton.scss"

type TextButtonProps = {
  label: string
  icon?: IconProp
  href?: string
  isExternalLink?: boolean
  isFullWidth?: boolean
  hasNoPaddingStart?: boolean
  isAlternate?: boolean
  onBtnClick?: React.MouseEventHandler<HTMLButtonElement>
}

function TextButton({
  label,
  icon,
  href,
  isExternalLink = false,
  isFullWidth = false,
  hasNoPaddingStart = false,
  isAlternate = false,
  onBtnClick,
}: TextButtonProps) {
  const classNames = `text-button ${
    isFullWidth ? "display-f" : "display-i-f"
  } ${
    hasNoPaddingStart ? "pt-1 pb-1 pr-1" : "p-1"
  } align-center bg-transparent ${
    isAlternate ? "text-tertiary text-hover-black" : "text-hover-tertiary"
  }`

  const content = icon ? (
    <React.Fragment>
      <FontAwesomeIcon icon={icon} fixedWidth />
      <p className="button ml-1">{label}</p>
    </React.Fragment>
  ) : (
    <p className="button">{label}</p>
  )

  if (href && href.length > 0) {
    // Has href, must be a link
    const attributes = isExternalLink
      ? { target: "_blank", rel: "noreferrer" }
      : {}

    if (isExternalLink) {
      return (
        <a href={href} className={classNames} {...attributes}>
          {content}
        </a>
      )
    } else if (href.startsWith("tel:") || href.startsWith("mailto:")) {
      return (
        <a href={href} className={classNames}>
          {content}
        </a>
      )
    } else {
      return (
        <Link to={href} className={classNames}>
          {content}
        </Link>
      )
    }
  } else {
    // Has no href, can't be a link
    return (
      <button className={classNames} onClick={onBtnClick}>
        {content}
      </button>
    )
  }
}

export default TextButton
