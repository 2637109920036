import * as React from "react"
import { motion } from "framer-motion"

import "./backdrop.scss"

type BackdropProps = {
  children: React.ReactNode
  onClick: React.MouseEventHandler<HTMLDivElement>
}

const Backdrop = ({ children, onClick }: BackdropProps) => {
  return (
    <motion.div
      onClick={onClick}
      className="backdrop pt-5 pb-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  )
}

export default Backdrop
