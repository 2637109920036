import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

import "./iconButton.scss"

type IconButtonProps = {
  children?: React.ReactNode
  label: string
  icon?: IconProp
  href?: string
  isExternalLink?: boolean
  onBtnClick?: React.MouseEventHandler<HTMLButtonElement>
}

function IconButton({
  children,
  label,
  icon,
  href,
  isExternalLink = false,
  onBtnClick,
}: IconButtonProps) {
  const classNames =
    "icon-button display-f justify-center align-center p-2 bg-transparent text-hover-tertiary"
  const iconAttributes = { "aria-label": label }

  if (href && href.length > 0) {
    // Has href, must be a link
    const attributes = isExternalLink
      ? { target: "_blank", rel: "noreferrer" }
      : {}
    if (isExternalLink) {
      return (
        <a
          href={href}
          className={classNames}
          {...attributes}
          {...iconAttributes}
        >
          {children ?? <FontAwesomeIcon icon={icon!} fixedWidth />}
        </a>
      )
    } else if (href.startsWith("tel:") || href.startsWith("mailto:")) {
      return (
        <a href={href} className={classNames} {...iconAttributes}>
          {children ?? <FontAwesomeIcon icon={icon!} fixedWidth />}
        </a>
      )
    } else {
      return (
        <Link to={href} className={classNames} {...iconAttributes}>
          {children ?? <FontAwesomeIcon icon={icon!} fixedWidth />}
        </Link>
      )
    }
  } else {
    // Has no href, can't be a link
    return (
      <button className={classNames} onClick={onBtnClick} {...iconAttributes}>
        {children ?? <FontAwesomeIcon icon={icon!} fixedWidth />}
      </button>
    )
  }
}

export default IconButton
