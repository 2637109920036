import * as React from "react"
import { useLocation } from "@reach/router"
import { motion, AnimatePresence } from "framer-motion"
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons"

import { TagManagerContext } from "../../core/contexts/tagManager"
import IconButton from "../buttons/iconButton"
import TextButton from "../buttons/textButton"
import Backdrop from "../backdrop/backdrop"
import SocialButtonsBar from "../socials/socialButtonsBar"
import Accordion from "../accordion/accordion"

import "./drawer.scss"

type DrawerProps = {
  open: boolean
  handleClose: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>
}

const rotate = {
  visible: (isOpen: boolean) => ({
    rotate: isOpen ? 180 : 0,
  }),
}

const slideIn = {
  hidden: {
    x: "100%",
    opacity: 0,
  },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: "easeInOut",
      delayChildren: 0.1,
      staggerDirection: 1,
    },
  },
  exit: {
    x: "100%",
    opacity: 0,
  },
}

const itemSlideIn = {
  hidden: {
    x: "100%",
    opacity: 0,
  },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
      mass: 0.6,
    },
  },
  exit: {
    x: "100%",
    opacity: 0,
  },
}

const solutionsIndex = 0
const servicesIndex = 1
const resourcesIndex = 2

const solutionsSubMenuItems = [
  {
    label: "Optimizing Your Sales and Marketing",
    href: "/solutions/optimizing-your-sales-and-marketing",
  },
  {
    label: "Creating a Content Ecosystem",
    href: "/solutions/creating-a-content-ecosystem",
  },
  {
    label: "Developing New Digital Products",
    href: "/solutions/developing-new-digital-products",
  },
]

const servicesSubMenuItems = [
  { label: "Strategy", href: "/services/strategy" },
  { label: "Product Development", href: "/services/product-development" },
  { label: "Digital Content", href: "/services/digital-content" },
]

const resourcesSubMenuItems = [
  { label: "Blog", href: "/resources/blog" },
  { label: "Insights", href: "/resources/insights" },
  { label: "Downloads", href: "/resources/downloads" },
]

const Drawer = ({ open, handleClose }: DrawerProps) => {
  // This approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [expanded, setExpanded] = React.useState<number | false>(false)
  const location = useLocation()
  const tagManager = React.useContext(TagManagerContext)

  const onSolutionsDropdownClick = () => {
    solutionsIndex === expanded
      ? setExpanded(false)
      : setExpanded(solutionsIndex)
  }
  const onServicesDropdownClick = () => {
    servicesIndex === expanded ? setExpanded(false) : setExpanded(servicesIndex)
  }
  const onResourcesDropdownClick = () => {
    resourcesIndex === expanded
      ? setExpanded(false)
      : setExpanded(resourcesIndex)
  }

  const onLinkClick = (item: { label: string; href: string }) => {
    tagManager?.onLinkClickEvent({
      link_type: "navigation",
      link_url: location.origin + item.href,
      link_name: item.label,
      link_location: "drawer",
    })
  }

  return (
    <AnimatePresence>
      {open && (
        <Backdrop onClick={handleClose}>
          <motion.div
            onClick={e => e.stopPropagation()}
            className="drawer mw-600 h-100 bg-white p-2"
            variants={slideIn}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <motion.div
              variants={itemSlideIn}
              className="row justify-space-between align-center pl-1 mb-2"
            >
              <h6 className="subtitle1 m-0 text-primary">Navigation</h6>
              <IconButton
                icon={faTimes}
                label="Close Navigation Menu"
                onBtnClick={handleClose}
              />
            </motion.div>
            <motion.div
              variants={itemSlideIn}
              onClick={() => onLinkClick({ label: "Home", href: "/" })}
            >
              <TextButton label="Home" href="/" />
            </motion.div>
            <motion.div
              variants={itemSlideIn}
              className="display-f justify-space-between"
            >
              <div
                onClick={() =>
                  onLinkClick({ label: "Solutions", href: "/solutions" })
                }
              >
                <TextButton label="Solutions" href="/solutions" />
              </div>
              <motion.div
                custom={solutionsIndex === expanded}
                animate="visible"
                variants={rotate}
              >
                <IconButton
                  label="Dropdown for Solutions"
                  icon={faChevronDown}
                  onBtnClick={onSolutionsDropdownClick}
                />
              </motion.div>
            </motion.div>
            <motion.div variants={itemSlideIn}>
              <Accordion i={solutionsIndex} expanded={expanded}>
                <div className="display-i-f direction-column pl-2 pr-2">
                  {solutionsSubMenuItems.map(item => (
                    <div key={item.href} onClick={() => onLinkClick(item)}>
                      <TextButton label={item.label} href={item.href} />
                    </div>
                  ))}
                </div>
              </Accordion>
            </motion.div>
            <motion.div
              variants={itemSlideIn}
              className="display-f justify-space-between"
            >
              <div
                onClick={() =>
                  onLinkClick({ label: "Services", href: "/services" })
                }
              >
                <TextButton label="Services" href="/services" />
              </div>
              <motion.div
                custom={servicesIndex === expanded}
                animate="visible"
                variants={rotate}
              >
                <IconButton
                  label="Dropdown for Services"
                  icon={faChevronDown}
                  onBtnClick={onServicesDropdownClick}
                />
              </motion.div>
            </motion.div>
            <motion.div variants={itemSlideIn}>
              <Accordion i={servicesIndex} expanded={expanded}>
                <div className="display-i-f direction-column pl-2 pr-2">
                  {servicesSubMenuItems.map(item => (
                    <div key={item.href} onClick={() => onLinkClick(item)}>
                      <TextButton label={item.label} href={item.href} />
                    </div>
                  ))}
                </div>
              </Accordion>
            </motion.div>
            <motion.div
              variants={itemSlideIn}
              className="display-f justify-space-between"
            >
              <div
                onClick={() =>
                  onLinkClick({ label: "Resources", href: "/resources" })
                }
              >
                <TextButton label="Resources" href="/resources" />
              </div>
              <motion.div
                custom={resourcesIndex === expanded}
                animate="visible"
                variants={rotate}
              >
                <IconButton
                  label="Dropdown for Resources"
                  icon={faChevronDown}
                  onBtnClick={onResourcesDropdownClick}
                />
              </motion.div>
            </motion.div>
            <motion.div variants={itemSlideIn}>
              <motion.div variants={itemSlideIn}>
                <Accordion i={resourcesIndex} expanded={expanded}>
                  <div className="display-i-f direction-column pl-2 pr-2">
                    {resourcesSubMenuItems.map(item => (
                      <div key={item.href} onClick={() => onLinkClick(item)}>
                        <TextButton label={item.label} href={item.href} />
                      </div>
                    ))}
                  </div>
                </Accordion>
              </motion.div>
              <motion.div
                variants={itemSlideIn}
                onClick={() =>
                  onLinkClick({ label: "Contact Us", href: "/contact-us" })
                }
              >
                <TextButton label="Contact Us" href="/contact-us" />
              </motion.div>
              <motion.p variants={itemSlideIn} className="p-1 mt-4">
                On a mission to become essential for influential small
                businesses and brands by delivering transformative cloud
                solutions, services, and resources to help them be digital,
                future-proof, and customer-centric.
              </motion.p>
              <motion.div variants={itemSlideIn} className="mt-4">
                <SocialButtonsBar />
              </motion.div>
            </motion.div>
          </motion.div>
        </Backdrop>
      )}
    </AnimatePresence>
  )
}

export default Drawer
