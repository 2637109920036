import * as React from "react"

import "./divider.scss"

type DividerProps = {
  isVertical?: boolean
  isBgDark?: boolean
}

const Divider = ({ isVertical = false, isBgDark = false }: DividerProps) => (
  <div
    className={`${
      isVertical ? "divider__vertical h-100" : "divider__horizontal w-100"
    } ${isBgDark ? "bg-white" : "bg-black"}`}
  ></div>
)

export default Divider
