import * as React from "react"
import { useLocation } from "@reach/router"
import { StaticImage } from "gatsby-plugin-image"
import { faBars } from "@fortawesome/free-solid-svg-icons"

import { TagManagerContext } from "../../core/contexts/tagManager"
import IconButton from "../buttons/iconButton"
import TextButton from "../buttons/textButton"
import Drawer from "../drawer/drawer"
import Divider from "../divider/divider"

import "./navbar.scss"

const menuItems = [
  { label: "Home", href: "/" },
  { label: "Solutions", href: "/solutions" },
  { label: "Services", href: "/services" },
  { label: "Resources", href: "/resources" },
  { label: "Contact Us", href: "/contact-us" },
]

const Navbar = () => {
  const location = useLocation()
  const tagManager = React.useContext(TagManagerContext)
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const handleDrawerClose = () => setDrawerOpen(!drawerOpen)

  const onLinkClick = (item: { label: string; href: string }) => {
    tagManager?.onLinkClickEvent({
      link_type: "navigation",
      link_url: location.origin + item.href,
      link_name: item.label,
      link_location: "header",
    })
  }

  return (
    <React.Fragment>
      <nav className="navbar bg-white w-100">
        <div className="row justify-space-between align-center">
          <div className="display-f align-center">
            <IconButton href="/" label="Home">
              <StaticImage
                src="../../images/icon.png"
                width={48}
                style={{ width: "48px" }}
                alt="iPhupha Digital's logo"
              />
            </IconButton>
            <p className="m-0 subtitle1">iPhupha Digital</p>
          </div>
          <div className="display-f pr-2">
            <div className="collapse collapse-lg direction-column">
              <div className="row">
                {menuItems.map((item, index) => (
                  <div
                    key={item.href}
                    className={index < menuItems.length - 1 ? "mr-2" : ""}
                    onClick={() => onLinkClick(item)}
                  >
                    <TextButton label={item.label} href={item.href} />
                  </div>
                ))}
              </div>
            </div>
            <div className="expand expand-lg">
              <IconButton
                icon={faBars}
                label="Open Navigation Menu"
                onBtnClick={handleDrawerClose}
              />
            </div>
          </div>
        </div>
        <Divider />
      </nav>

      <Drawer open={drawerOpen} handleClose={handleDrawerClose} />
    </React.Fragment>
  )
}

export default Navbar
