import * as React from "react"
import { useLocation } from "@reach/router"

import { TagManagerContext } from "../../core/contexts/tagManager"
import TextButton from "../buttons/textButton"
import Divider from "../divider/divider"
import SocialButtonsBar from "../socials/socialButtonsBar"

import "./footer.scss"

const solutionsSubMenuItems = [
  {
    label: "Optimizing Your Sales and Marketing",
    href: "/solutions/optimizing-your-sales-and-marketing",
  },
  {
    label: "Creating a Content Ecosystem",
    href: "/solutions/creating-a-content-ecosystem",
  },
  {
    label: "Developing New Digital Products",
    href: "/solutions/developing-new-digital-products",
  },
]

const servicesSubMenuItems = [
  { label: "Strategy", href: "/services/strategy" },
  { label: "Product Development", href: "/services/product-development" },
  { label: "Digital Content", href: "/services/digital-content" },
]

const resourcesSubMenuItems = [
  { label: "Blog", href: "/resources/blog" },
  { label: "Insights", href: "/resources/insights" },
  { label: "Downloads", href: "/resources/downloads" },
]

const aboutSubMenuItems = [
  { label: "About Us", href: "/about-us" },
  { label: "Contact Us", href: "/contact-us" },
]

const legalDocsSubMenuItems = [
  { label: "Terms", href: "/terms" },
  { label: "Privacy", href: "/privacy" },
]

const Footer = () => {
  const location = useLocation()
  const tagManager = React.useContext(TagManagerContext)

  const onLinkClick = (item: { label: string; href: string }) => {
    tagManager?.onLinkClickEvent({
      link_type: "navigation",
      link_url: location.origin + item.href,
      link_name: item.label,
      link_location: "footer",
    })
  }
  return (
    <React.Fragment>
      <footer className="bg-primary text-white w-100 pt-2 pb-5 mt-5">
        <div className="container">
          <div className="row mb-3">
            <div className="col-xs-12 col-md-6 col-lg-3 pt-3 pr-2">
              <h6>Solutions</h6>
              {solutionsSubMenuItems.map(item => (
                <div key={item.href} onClick={() => onLinkClick(item)}>
                  <TextButton
                    isFullWidth={true}
                    hasNoPaddingStart={true}
                    label={item.label}
                    href={item.href}
                  />
                </div>
              ))}
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3 pt-3 pr-2">
              <h6>Services</h6>
              {servicesSubMenuItems.map(item => (
                <div key={item.href} onClick={() => onLinkClick(item)}>
                  <TextButton
                    isFullWidth={true}
                    hasNoPaddingStart={true}
                    label={item.label}
                    href={item.href}
                  />
                </div>
              ))}
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3 pt-3 pr-2">
              <h6>Resources</h6>
              {resourcesSubMenuItems.map(item => (
                <div key={item.href} onClick={() => onLinkClick(item)}>
                  <TextButton
                    isFullWidth={true}
                    hasNoPaddingStart={true}
                    label={item.label}
                    href={item.href}
                  />
                </div>
              ))}
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3 pt-3 pr-2">
              <h6>About</h6>
              {aboutSubMenuItems.map(item => (
                <div key={item.href} onClick={() => onLinkClick(item)}>
                  <TextButton
                    isFullWidth={true}
                    hasNoPaddingStart={true}
                    label={item.label}
                    href={item.href}
                  />
                </div>
              ))}
            </div>
            <div className="col-xs-12 col-md-6 col-lg-3 pt-3 pr-2">
              <h6>Legal</h6>
              {legalDocsSubMenuItems.map(item => (
                <div key={item.href} onClick={() => onLinkClick(item)}>
                  <TextButton
                    isFullWidth={true}
                    hasNoPaddingStart={true}
                    label={item.label}
                    href={item.href}
                  />
                </div>
              ))}
            </div>
          </div>
          <Divider isBgDark={true} />
          <SocialButtonsBar />
          <Divider isBgDark={true} />
          <p className="caption text-white mt-5 mb-5">
            © 2021 iPhupha Digital (Pty) Ltd. All Rights Reserved.
          </p>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
